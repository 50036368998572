import {
    Box,
    Heading,
    Text,
    Image,
    Flex,
    Button,
    Checkbox,
    VStack,
    Input,
    AccordionPanel,
    AccordionIcon, AccordionButton, AccordionItem, Accordion, Link
} from '@chakra-ui/react';
import React from "react";
import SubscriptionBox from "./SubscriptionBox";
import {AddIcon} from "@chakra-ui/icons";

const Page2 = () => {

    function Dot(props) {
        // Use destructuring to extract `color` from props with a default value
        const { color = "#5E8FA3", size = "5.5", r='5' } = props;
        const viewBox = "0 0 " + size *2 + " " + size*2;
        return (
            <svg width={size*2} height={size*2} viewBox={viewBox} fill="none">
                <circle cx={size} cy={size} r={r} fill={color} />
            </svg>
        );
    }
    const syllables = ['neu', 'ro', 'di', 'ver', 'si', 'ty'];
    const defs = [
        {
            question: "Attention Deficit Hyperactivity Disorder (ADHD)",
            answer: () => (
                <VStack align="left" spacing={4}>
                    <Text>ADHD is a disorder marked by an ongoing pattern of inattention and/or hyperactivity-impulsivity that interferes with functioning or development. Inattention means a person wanders off task, lacks persistence, has difficulty sustaining focus, and is disorganized; these problems are not due to defiance or lack of comprehension. Hyperactivity means a person seems to move about constantly, including in situations where it is not appropriate, or excessively fidgets, taps, or talks.</Text>
                    <Text color={'#CC7B4E'}>In educational settings, ADHD is often recognized as a condition that can impact a student's ability to focus, regulate their behavior, and manage tasks in the classroom. Accommodations and support strategies may be implemented to help students with ADHD succeed academically.</Text>
                </VStack>
            )
        },
        {
            question: "Dyslexia",
            answer: () => (
                <VStack align="left" spacing={4}>
                    <Text>Other names: <Box as="span" color={'#CC7B4E'}>Learning Disability in Reading</Box></Text>
                    <Text>Dyslexia is a specific learning disability that is neurobiological in origin. It is characterized by difficulties with accurate and/or fluent word recognition and by poor spelling and decoding abilities.</Text>
                    <Text color={'#CC7B4E'}>In education, dyslexia is acknowledged as a specific learning disability in reading. Students with dyslexia may receive specialized instruction and accommodations tailored to their individual needs to support their reading development.</Text>
                </VStack>
            )
        },
        {
            question: "Dyscalculia",
            answer: () => (
                <VStack align="left" spacing={4}>
                    <Text>Other names: <Box as="span" color={'#CC7B4E'}>Learning Disability in Math</Box></Text>
                    <Text>Dyscalculia is a specific learning disability in math. People with dyscalculia have difficulty with math at many levels. They often struggle in understanding numbers, learning how to manipulate numbers, and performing mathematical calculations.</Text>
                    <Text color={'#CC7B4E'}>Dyscalculia is recognized as a learning disability in mathematics within educational settings. Students with dyscalculia may require additional support and accommodations to address challenges with mathematical concepts and computations.</Text>
                </VStack>
            )
        },
        {
            question: "Dysgraphia",
            answer: () => (
                <VStack align="left" spacing={4}>
                    <Text>Other names: <Box as="span" color={'#CC7B4E'}>Learning Disability in Writing</Box></Text>
                    <Text>Dysgraphia is a neurological disorder characterized by writing disabilities. Specifically, the disorder causes a person's writing to be distorted or incorrect. Individuals with dysgraphia may have difficulty with spelling, organizing written language, and expressing thoughts in writing.</Text>
                    <Text color={'#CC7B4E'}>Dysgraphia is acknowledged as a learning disability in writing within educational contexts. Students with dysgraphia may benefit from accommodations such as assistive technology, alternative methods of writing, or occupational therapy to support their writing skills.</Text>
                </VStack>
            )
        },
        {
            question: "Dyspraxia",
            answer: () => (
                <VStack align="left" spacing={4}>
                    <Text>Other names: <Box as="span" color={'#CC7B4E'}>Developmental Coordination Disorder</Box></Text>
                    <Text>Developmental coordination disorder (DCD), also known as dyspraxia, is a condition characterized by impairment in the ability to plan and carry out sensory and motor tasks.</Text>
                    <Text color={'#CC7B4E'}>Students with dyspraxia may struggle with tasks such as handwriting, physical education, and organizational skills, requiring tailored support and accommodations to facilitate their learning and development.</Text>
                </VStack>
            )
        },
        {
            question: "Autism Spectrum Disorder (ASD)",
            answer: () => (
                <VStack align="left" spacing={4}>
                    <Text>Autism Spectrum Disorder: Autism spectrum disorder (ASD) is the name for a group of developmental disorders. ASD includes a wide range, "a spectrum," of symptoms, skills, and levels of disability.</Text>
                    <Text>People with ASD often have these characteristics: Ongoing social problems that include difficulty communicating and interacting with others, repetitive behaviors as well as limited interests or activities, symptoms that typically are recognized in the first two years of life, and symptoms that hurt the individual's ability to function socially, at school or work, or in other areas of life.</Text>
                    <Text color={'#CC7B4E'}>ASD is recognized as a developmental disorder that can impact various aspects of a student's functioning, including social skills, communication, and behavior. In educational settings, individuals with ASD may receive specialized support and interventions tailored to their unique strengths and needs.</Text>
                </VStack>
            )
        },
        {
            question: "Mental Health",
            answer: () => (
                <VStack align="left" spacing={4}>
                    <Text>Mental health encompasses a broad range of conditions that affect an individual's emotional, psychological, and social well-being. This includes conditions such as anxiety disorders, mood disorders, psychotic disorders, and more.</Text>
                    <Text color={'#CC7B4E'}>Treatment often involves therapy, medication, or a combination of both. Mental Health Support involves providing counseling, therapy, and support services to students who may be experiencing mental health challenges. This can include individual counseling sessions, family therapy, group therapy, and other interventions aimed at addressing the student's emotional and psychological needs within the educational setting.</Text>
                </VStack>
            )
        },
        {
            question: "Acquired Neurodiversity",
            answer: () => (
                <VStack align="left" spacing={4}>
                    <Text as="b">TBI (Traumatic Brain Injury):</Text>
                    <Text>Traumatic brain injury (TBI) occurs when a sudden trauma causes damage to the brain. TBI can result when the head suddenly and violently hits an object, or when an object pierces the skull and enters brain tissue.</Text>
                    <Text color={'#CC7B4E'}>Educational interventions for students with TBI may include accommodations such as extended time for assignments, modified coursework, and specialized support services.</Text>
                    <Text as="b">Fetal Alcohol Spectrum Disorder (FASD):</Text>
                    <Text>Fetal alcohol spectrum disorders (FASDs) are a group of conditions that can occur in a person whose mother drank alcohol during pregnancy. These effects can include physical problems and problems with behavior and learning.</Text>
                    <Text color={'#CC7B4E'}>Educational interventions may focus on providing structured environments, visual supports, and individualized instruction to accommodate the student's unique needs.</Text>
                    <Text as="b">Genetic Disorders:</Text>
                    <Text>A genetic disorder is a disease caused in whole or in part by a change in the DNA sequence away from the normal sequence. Genetic disorders can be caused by a mutation in one gene (monogenic disorder), by mutations in multiple genes (multifactorial inheritance disorder), by a combination of gene mutations and environmental factors, or by damage to chromosomes (changes in the number or structure of entire chromosomes, the structures that carry genes).</Text>
                    <Text color={'#CC7B4E'}>Educational interventions for students with genetic disorders may include specialized instruction, assistive technology, and accommodations tailored to the student's specific needs.</Text>
                    <Text as="b">Seizure Disorders:</Text>
                    <Text>Seizures are changes in the brain's electrical activity. These changes can cause dramatic, noticeable symptoms or even no symptoms at all. The symptoms of a severe seizure include violent shaking and a loss of control.</Text>
                    <Text color={'#CC7B4E'}>Educational interventions may include training staff on seizure management, developing seizure action plans, and providing a safe and supportive environment for students with seizure disorders.</Text>
                </VStack>
            )
        },
        {
            question: "Tourette Syndrome / Tics",
            answer: () => (
                <VStack align="left" spacing={4}>
                    <Text>Tourette syndrome (TS) is a neurological disorder characterized by repetitive, stereotyped, involuntary movements and vocalizations called tics. The early symptoms of Tourette syndrome are typically noticed first in childhood, with the average onset between the ages of 3 and 9 years.</Text>
                    <Text color={'#CC7B4E'}>Tic Disorders, including Tourette's Syndrome, are recognized as conditions that may impact a student's behavior and social interactions in educational settings. Educational interventions may focus on providing accommodations to support the student's learning and minimizing distractions caused by tics in the classroom.</Text>
                </VStack>
            )
        },
    ];

    return (
        <Flex
            direction="column"
            align="center"
            justify="flex-start"
            textAlign="center"
            my={{ base: "50px", md: "0" }}
            pt={{ base: "50px", md: "5%" }}
            position="relative"
            px={4}
            bgColor={'#FFFCFA'}
        >
            <Image src="/img_2.png" alt="Left Image" position="absolute" left={{ base: 0, md: "0px"}} top={{ base: "5%", md: "5%" }} transform="translateY(-50%)" maxH={{ base: "70px", md: "240px" }} />
            <Box maxWidth="900px" width="100%" px={0} id={'neurodiversitySection'}>
                <Flex justifyContent="center" alignItems="center" wrap="wrap">
                    {syllables.map((syllable, index) => (
                        <Flex key={index} alignItems="center">
                            <Text color="#5E8FA3" fontSize={{ base: '22px', md: '58px' }} fontWeight="700" mx={{ base: "3px", md: "10px" }}>{syllable}</Text>
                            {index < syllables.length - 1 && <Dot size={'3.5'} r={'3'}/>}
                        </Flex>
                    ))}
                    <Text color="#D48282" fontStyle={'italic'} fontSize={{ base: '22px', md: '58px' }} fontWeight="700" mx={'10px'}>(noun)</Text>
                </Flex>

                <Box display={{ base: 'none', md: 'inline' }}>
                    <Text color="#525061" mt={4} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500"> <Link href="https://www.nih.gov" isExternal>
                        The National Institutes of Health (NIH)
                    </Link> recognizes neurodiversity as a natural</Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">part of human variation, emphasizing respect and value for neurological</Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">differences. Embracing neurodiversity means celebrating the unique</Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">strengths and contributions of the 20% of society with diverse brain</Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">functions, advocating for their acceptance, support, and inclusion.</Text>
                </Box>
                <Box display={{ base: 'inline', md: 'none' }}>
                    <Text color="#525061" mt={4} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">      <Link href="https://www.nih.gov" isExternal>
                        The National Institutes of Health (NIH)
                    </Link>
                    </Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">recognizes neurodiversity as a natural part </Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">of human variation, emphasizing respect </Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">and value for neurological differences.</Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">Embracing neurodiversity means </Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">celebrating the unique strengths and </Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">contributions of the 20% of society with </Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">diverse brain functions, advocating for their </Text>
                    <Text color="#525061" mt={0} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">acceptance, support, and inclusion.</Text>
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center" width="100%" mt={'50px'} mb={'10px'}>
                    <Flex justifyContent="center" alignItems="center" wrap="wrap" bgColor="#EFF4F6" maxWidth="640px" borderRadius="10px" height={{ base: '46px', md: '64px' }} px={{ base: '10px', md: '20px' }}>
                        <Flex alignItems="center">
                            <Dot color="#333143" size={'3.5'} r={'3'}/>
                            <Text color="#333143" fontSize={{ base: '14px', md: '20px' }} fontWeight="700" mx={{ base: '5px', md: '10px' }}>Medical Definition</Text>
                        </Flex>
                        <Flex ml={{ base: '5px', md: '20px' }} alignItems="center">
                            <Dot color={"#CC7B4E"} size={'3.5'} r={'3'}/>
                            <Text color="#CC7B4E" fontSize={{ base: '14px', md: '20px' }} fontWeight="700" mx={{ base: '5px', md: '10px' }}>Educational Definition</Text>
                        </Flex>
                    </Flex>
                </Box>
                <Accordion allowToggle>
                    {defs.map((def, index) => (
                        <AccordionItem key={index}
                                       borderTop={index === 0 ? "none" : "1px solid"}
                                       borderBottom="1px solid"
                                       borderColor="#F4BEA0">
                            <h2>
                                <AccordionButton>
                                    <Box flex="1" textAlign="left" color="#333143" fontSize={'25px'} my={'10px'} fontWeight={'400'}>
                                        {def.question}
                                    </Box>
                                    <AddIcon color={'#F4BEA0'}/>
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4} fontSize={'20px'} color="#333143" flex="1" textAlign="left">
                                {def.answer()}
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
                </Accordion>
            </Box>
            <Image src={'flower.png'} my={'100px'} display={{ base: 'none', md: 'inline' }}/>
            <Box maxWidth="900px" width="100%" px={0} mt={'100px'}>
                <Heading color="#5E8FA3" fontSize={{ base: '28px', md: '39px' }} fontWeight="700">What Parents Like You Have Told Us</Heading>
                <Text color="#9F9DAD" mt={4} lineHeight={'28px'} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">Here is a snapshot of the experiences shared with us by 65 families of neurodiverse kids.</Text>
            </Box>
            <Image src={'cloud.png'} my={'50px'} display={{ base: 'none', md: 'inline' }}/>
            <Image src={'cloud_mobile.png'} my={'50px'} display={{ base: 'inline', md: 'none' }}/>
            <Box maxWidth="900px" width="100%" px={0}>
                <Heading color="#5E8FA3" fontSize={{ base: '28px', md: '39px' }} fontWeight="700">We’re on a Mission</Heading>
            </Box>
            <SubscriptionBox/>
            <Box mt={'50px'}></Box>
        </Flex>
    );
};

export default Page2;
