import React from 'react';
import { Box } from '@chakra-ui/react';
import Navbar from './components/Navbar';
import Jumbotron from './components/Jumbotron';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import PrivacyPolicy from "./components/PrivacyPolicy";
import Terms from "./components/Terms";
import LandingPage from "./components/LandingPage";
import About from "./components/About";

function App() {
    return (
        <Router>
            <Navbar />
            <Routes>
                <Route path="/" element={<LandingPage />} />
                <Route path="/privacy-policy" element={<PrivacyPolicy />} />
                <Route path="/terms" element={<Terms />} />
                <Route path="/about" element={<About />} />
            </Routes>
        </Router>
    );
}

export default App;
