import {
    Box,
    Heading,
    Text,
    Image,
    Flex,
    Button,
    Checkbox,
    VStack,
    Input,
    GridItem,
    Icon,
    Circle, Grid
} from '@chakra-ui/react';
import React from "react";
import SubscriptionBox from "./SubscriptionBox";
import {MdBackpack, MdBackup, MdLibraryBooks, MdPeople, MdSchool} from "react-icons/md";

const Page1 = () => {

    const features = [
        {
            icon: MdBackpack,
            title: 'Experiences',
            description: ()=> {
                return <Box  mt={2} color={'#525061'}>
                    <Box display={{base: 'none', md: 'inline'}}>
                        <Text>after-school activities, tutoring, </Text>
                        <Text>therapy, diagnostic services,</Text>
                        <Text>summer camps</Text>
                    </Box>
                    <Box display={{base: 'inline', md: 'none'}}>
                        <Text>after-school activities, tutoring, therapy,</Text>
                        <Text>diagnostic services, summer camps</Text>
                        <Text></Text>
                    </Box>
                </Box>
            },
            iconBgColor: "#F4BEA0",
            iconColor: "#AA643D"
        },
        {
            icon: MdLibraryBooks,
            title: 'Resources',
            description: ()=> {
                return <Box  mt={2} color={'#525061'}>
                    <Box display={{base: 'none', md: 'inline'}}>
                        <Text>blogs, podcasts, books, </Text>
                        <Text>scientific research, FAQs</Text>
                    </Box>
                    <Box display={{base: 'inline', md: 'none'}}>
                        <Text>blogs, podcasts, books, scientific </Text>
                        <Text>research, FAQs</Text>
                        <Text></Text>
                    </Box>
                </Box>
            },
            iconBgColor: "#9EBCC8",
            iconColor: "#395662"
        },
        {
            icon: MdPeople,
            title: 'Community',
            description: ()=> {
                return <Box  mt={2} color={'#525061'}>
                    <Box display={{base: 'none', md: 'inline'}}>
                        <Text>guides, virtual &</Text>
                        <Text>in-person events,</Text>
                        <Text>share tips & tricks</Text>
                    </Box>
                    <Box display={{base: 'inline', md: 'none'}}>
                        <Text>guides, virtual & in-person events,</Text>
                        <Text>share tips & tricks</Text>
                        <Text></Text>
                    </Box>
                </Box>
            },
            iconBgColor: "#F7D4B2",
            iconColor: "#BA8451"
        },
    ];
    const Superscript = ({ mobileTop, desktopTop, desktopFontSize, mobileFontSize, children }) => {
        return (
            <Box
                as="sup"
                style={{ position: 'relative' }}
                top={{ base: mobileTop, md: desktopTop }}  // Responsive top positioning
                fontSize={{ base: mobileFontSize, md: desktopFontSize }}
            >
                {children}
            </Box>
        );
    };

    return (
        <Flex
            direction="column"
            align="center"
            justify="flex-start"
            textAlign="center"
            py={{ base: "50px", md: "4%" }}
            my={{ base: "50px", md: "0" }}
            position="relative"
            px={4}
            bgColor={'#DFE9ED'}
        >

            <Box maxWidth="900px" width="100%" px={0} display={{base: 'none', md: 'inline'}}>
                <Heading color="#5E8FA3" fontSize={{ base: '28px', md: '39px' }} fontWeight="700">What is Keikens<Superscript mobileTop="-0.9em" desktopTop="-0.9em" mobileFontSize={'12px'} desktopFontSize={'15px'}>SM</Superscript>?</Heading>
                <Text color="#525061" mt={4} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">Keikens, which means ‘experience’ in Japanese, exists to reduce complexity and</Text>
                <Text color="#525061" mt={0} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">enable support to families of neurodiverse children by providing experiences,</Text>
                <Text color="#525061" mt={0} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">resources and a community of supportive families in the same shoes.</Text>
            </Box>
            <Box maxWidth="900px" width="100%" px={0} display={{base: 'inline', md: 'none'}}>
                <Heading color="#5E8FA3" fontSize={{ base: '28px', md: '39px' }} fontWeight="700">What is Keikens<Superscript mobileTop="-0.9em" desktopTop="-0.9em" mobileFontSize={'12px'} desktopFontSize={'15px'}>SM</Superscript>?</Heading>
                <Text color="#525061" mt={4} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">Keikens, which means ‘experience’ </Text>
                <Text color="#525061" mt={0} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">in Japanese, exists to reduce complexity</Text>
                <Text color="#525061" mt={0} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">and enable support to families of</Text>
                <Text color="#525061" mt={0} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">neurodiverse children by providing</Text>
                <Text color="#525061" mt={0} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">experiences, resources and a community</Text>
                <Text color="#525061" mt={0} fontSize={{ base: '14px', md: '20px' }} fontWeight="500">of supportive families in the same shoes.</Text>
            </Box>

            <Grid
                templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} // Changes here
                gap={6}
                mt={{ base: "32px", md: "50px" }}
                maxWidth="1000px"
                width="100%"
            >
                {features.map((feature, index) => (
                    <GridItem key={index}>
                        <Box textAlign="center">
                            <Circle size="70px" bg={feature.iconBgColor} color={feature.iconColor} mx="auto">
                                <Icon as={feature.icon} w={10} h={10} />
                            </Circle>
                            <Text fontSize="20px" color="#4B7383" fontWeight="bold" mt={4}>
                                {feature.title}
                            </Text>
                            <Box>
                                {feature.description()}
                            </Box>
                        </Box>
                    </GridItem>
                ))}
            </Grid>
        </Flex>
    );
};

export default Page1;
