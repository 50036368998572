import React from 'react';
import {
    Box,
    Flex,
    Text,
    Link,
    Image,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    IconButton,
    Avatar,
    Button
} from '@chakra-ui/react';
import {HamburgerIcon} from "@chakra-ui/icons";

const Navbar = () => {
    return (
        <Flex justifyContent="space-between" alignItems="center" p={4} pb={2} bgColor={'#F5F5F8'}>
            <Link href="/" >
                <Image src="/img_3.png" alt="Keikens Logo" width={'220px'}/>
            </Link>
            {/*<Flex display={{ base: 'none', md: 'flex' }} alignItems="center" spacing={'20px'}>*/}
            {/*    <Link href="/privacy-policy" color="blue.500" display={{ base: 'none', md: 'block' }} mr={'20px'}>*/}
            {/*        Privacy Policy*/}
            {/*    </Link>*/}

            {/*    <Link href="/terms" color="blue.500" display={{ base: 'none', md: 'block' }}>*/}
            {/*        Terms of Service*/}
            {/*    </Link>*/}
            {/*</Flex>*/}

            <Menu>
                <MenuButton as={IconButton} icon={<HamburgerIcon />} display={{ base: 'inline-flex', md: 'inline-flex' }} variant="outline" />
                <MenuList>
                    <MenuItem as={Link} href="/about">
                        About Keikens
                    </MenuItem>
                    <MenuItem as={Link} href="/privacy-policy">
                        Privacy Policy
                    </MenuItem>
                    <MenuItem as={Link} href="/terms">
                        Terms of Service
                    </MenuItem>
                </MenuList>
            </Menu>
        </Flex>
    );
};

export default Navbar;
