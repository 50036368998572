import {Box, Container, Heading, ListItem, Text, UnorderedList, VStack} from '@chakra-ui/react';
import React from "react";

const PrivacyPolicy = ({ textColor = '#9F9DAD', textSize = 'md' }) => {
    return (
        <Box height={'100%'}>
            <Container maxW="container.xl" py="12">
                <VStack spacing="4" align="start">
                    <Heading as="h1" size="xl">KEIKENS PRIVACY POLICY</Heading>
                    <Text>LAST UPDATED ON: March 01, 2024</Text>
                    <Text>
                        The Privacy Policy is categorized and catalogued into separate links for ease of reference. The Privacy Policy is cumulative and form part of a single document and the same shall be applicable on all Users in its entirety for the use of Our Services, save for the clauses inconsistent with the federal/ state or local laws applicable where the Provider or User is located.
                    </Text>
                    <Text>
                        This Privacy Policy explains the collection, usage, storage, protection, and disclosure of your Personal Identifying Information through our Services. Keikens LLC is the data controller for your Personal Identifying Information collected through our Services (please refer to the Terms of Use for more information). We are committed to respecting and protecting your privacy, but certain information is required to be collected to provide you with our Services or in compliance with legal requirements.
                    </Text>
                    <Heading as="h2" size="md">The Privacy Policy includes:</Heading>
                    <UnorderedList>
                        <ListItem >Information Collection</ListItem>
                        <ListItem>Use of Information</ListItem>
                        <ListItem>Personal Information We Collect</ListItem>
                        <ListItem>Disclosure of Information</ListItem>
                        <ListItem>Account Deletion</ListItem>
                        <ListItem>Data Retention</ListItem>
                        <ListItem>Information Protection</ListItem>
                        <ListItem>Changes to this Privacy Policy</ListItem>
                        <ListItem>Contact Us</ListItem>
                    </UnorderedList>
                    <Heading as="h2" size="md">INFORMATION COLLECTION</Heading>
                    <Text>
                        As part of the user’s access to our Services, Keikens must receive or collect some information to operate, provide, improve, customize, support and market our Services, including, when you access or use our Services. Some Services cannot be provided to a user without collection of information, including your email id at the time of registration.
                    </Text>
                    <Heading as="h3" size="sm">INFORMATION PROVIDED BY A PROVIDER OR USER</Heading>
                    <Text>Account Information: At the time of registration on Keikens, you are required to create an account and provide your name, email address, and other information based on your account type.</Text>
                    <Text>Your Content: Keikens allows users to share Content during their access and use of our Services (please refer to our Partner Agreement or EULA, as applicable, for information on the same). This information is retained for Content moderation and publication.</Text>
                    <Text>Optional Information: We may allow the user to add some optional information which will help us in providing you with our Services more effectively.</Text>
                    <Heading as="h3" size="sm" >INFORMATION COLLECTED AUTOMATICALLY</Heading>
                    <Text fontSize={textSize} >In addition to the information that is provided to us by a Provider or User, we collect event information related to your interactions and engagement with our Services. For example, the activities and experiences that you interact with.</Text>
                    <Heading as="h2" size="md">INFORMATION PROVIDED BY OTHERS</Heading>
                    <Text>
                        Some information about a user is also collected through third-parties, such as other Providers or Users, email service providers, payment aggregators. This includes:
                    </Text>
                    <UnorderedList>
                        <ListItem>Other Providers and Users: We collect information about other users’ interaction with your Content or reports of violation of our Policies or community guidelines.</ListItem>
                        <ListItem>Third Party Service Providers: We allow you to use our Services with the assistance of certain third-party service providers, including email service providers. This includes your email id, and other essential information required for providing our Services to our Providers and Users.</ListItem>
                    </UnorderedList>

                    <Heading as="h2" size="md">USE OF INFORMATION</Heading>
                    <Text>The information collected or received from our users allows us to deliver and improve our Services. The information helps us to keep our users and the community safe. The information also helps us to provide you with advertising that may be relevant to you. The use of your information includes the following purposes:</Text>
                    <UnorderedList>
                        <ListItem>Creating and administering your account;</ListItem>
                        <ListItem>Providing customer support and responding to requests and reports;</ListItem>
                        <ListItem>Communicating regarding our Services;</ListItem>
                        <ListItem>Collecting and storing your Content for other Providers and Users to interact with;</ListItem>
                        <ListItem>Personalizing your user experience on our Services;</ListItem>
                        <ListItem>Reviewing customer care interactions to improve our Services;</ListItem>
                        <ListItem>Developing and testing new features or services.</ListItem>
                    </UnorderedList>

                    <Text>CONNECTING YOU WITH OTHER PROVIDERS AND USERS</Text>
                    <UnorderedList>
                        <ListItem>Enabling Providers to list their ongoing and upcoming activities and experiences;</ListItem>
                        <ListItem>Enabling Users to register for desirable activities and experiences;</ListItem>
                        <ListItem>Allowing Providers to manage registrations, bookings and accept payments.</ListItem>
                    </UnorderedList>

                    <Text>ENSURING COMMITMENT TO COMMUNITY GUIDELINES</Text>
                    <UnorderedList>
                        <ListItem>Monitoring and acting against users and Content in violation of our Policies;</ListItem>
                        <ListItem>Responding to user reports;</ListItem>
                        <ListItem>Using reported Content or prohibited Content to create systems and models that may be automated for faster detection and action against violative Content or conduct.</ListItem>
                    </UnorderedList>

                    <Text>PREVENTING AND REMOVING ILLEGAL OR UNAUTHORIZED CONDUCT</Text>
                    <UnorderedList>
                        <ListItem>Finding and addressing ongoing, suspected, or alleged violations of our Policies;</ListItem>
                        <ListItem>Understanding and creating automated countermeasure systems to address violations and prevent repeated offences of similar nature;</ListItem>
                        <ListItem>Enforcing or exercising our rights as set out in our Policies;</ListItem>
                        <ListItem>Enforcing the rights of our third-party service providers and other users as set out in our Policies;</ListItem>
                        <ListItem>Communicating with our users or third-parties who submit reports regarding action taken on the reported illegal or unauthorized conduct.</ListItem>
                    </UnorderedList>

                    <Heading as="h2" size="md">LEGAL COMPLIANCE</Heading>
                    <Text>Compliance with legal requirements. For example, directions of any court of law;</Text>
                    <Text>Reporting illegal Content or conduct to law enforcement agencies;</Text>
                    <Text>Assisting law enforcement agencies in investigation of reported illegal Content or conduct.</Text>

                    <Heading as="h2" size="md">MARKETING AND ADVERTISEMENT</Heading>
                    <Text>By acceptance of our Policies, you consent to the use of your information to provide you with advertisements that may be of your interest, and to send you marketing communications where legally required. You can revoke your consent at any time by writing an email to _____________ with the subject line: “consent for marketing and advertisement”.</Text>

                    <Heading as="h2" size="md">PERSONAL INFORMATION WE COLLECT</Heading>
                    <Text>Automatic Collection of Personal Information. We may collect certain information automatically when you use our Services, such as your Internet protocol (IP) address, user settings, MAC address, cookie identifiers, mobile carrier, mobile advertising and other unique identifiers, browser or device information, location information (including approximate location derived from IP address), and Internet service provider. We may also automatically collect information regarding your use of our Services, such as pages that you visit before, during and after using our Services, information about the links you click, the types of content you interact with, the frequency and duration of your activities, and other information about how you use our Services.
                    </Text>
                    <Text>Cookie Policy (and Other Technologies). We, as well as third parties that provide content, advertising, or other functionality on our Services, may use cookies, pixel tags, and other technologies (“Technologies”) to automatically collect information through your use of our Services.
                    </Text>
                    <UnorderedList>
                        <ListItem>Cookies. Cookies are small text files placed in device browsers that store preferences and facilitate and enhance your experience.
                        </ListItem>
                            <ListItem>Pixel Tags/Web Beacons. A pixel tag (also known as a web beacon) is a piece of code embedded in our Services that collects information about engagement on our Services. The use of a pixel tag allows us to record, for example, that a user has visited a particular web page or clicked on a particular advertisement. We may also include web beacons in e-mails to understand whether messages have been opened, acted on, or forwarded.
                            </ListItem>
                    </UnorderedList>
                    <Text>
                    Our uses of these Technologies fall into the following general categories:
                    </Text>
                    <UnorderedList>
                    <ListItem> Operationally Necessary. This includes Technologies that allow you access to our Services, applications, and tools that are required to identify irregular website behavior, prevent fraudulent activity, improve security, or allow you to make use of our functionality;
                    </ListItem><ListItem>Performance-Related. We may use Technologies to assess the performance of our Services, including as part of our analytic practices to help us understand how individuals use our Services (see Analytics below);
                    </ListItem><ListItem>Functionality-Related. We may use Technologies that allow us to offer you enhanced functionality when accessing or using our Services. This may include identifying you when you sign into our Services or keeping track of your specified preferences, interests, or past items viewed;
                    </ListItem><ListItem>Advertising- or Targeting-Related. We may use first party or third-party Technologies to deliver content, including ads relevant to your interests, on our Services or on third-party digital properties.
                    </ListItem><ListItem>Analytics. We may use Technologies and other third-party tools to process analytics information on our Services. These Technologies allow us to better understand how our digital Services are used and to continually improve and personalize our Services. Some of our analytics partners include:
                    </ListItem><ListItem>Google Analytics. For more information about how Google uses your personal
                        information (including for its own purposes, e.g., for profiling or linking it to other data),
                        please visit <a href={"https://policies.google.com/technologies/partner-sites"}>Google Analytics
                            Privacy Policy</a>. To learn more about how to opt-out of Google Analytics’ use of your
                        information, please click <a href={'https://support.google.com/analytics/answer/9019185?hl=en#zippy=%2Cin-this-article'}>here</a>
                    </ListItem><ListItem>CrazyEgg. We use CrazyEgg’s session replay analytics services. This allows us
                        to record and replay an individual’s interaction with the Services. For more information about how CrazyEgg uses your personal information, please visit the “Personal Data collected from a visitor of a CrazyEgg Enabled Site” section of <a href={"https://www.crazyegg.com/privacy"}>CrazyEgg’s Privacy Policy</a>. To learn more about how to opt-out of CrazyEgg’s use of your information, please click <a href={'https://www.crazyegg.com/opt-out'}>here</a>.
                    </ListItem>
                    </UnorderedList>
                    <Heading as="h2" size="md">DISCLOSURE OF INFORMATION</Heading>
                    <Text>For access to our Services: When you share Content on our Services, you allow us to make that Content available for other Providers and Users.</Text>
                    <Text>With Third Party Service Providers: We may disclose information to our third party service providers that have been engaged to carry out specific work for us. For example, our cloud service provider and payment gateway. For this, your information may be transferred, processed, and stored internationally, subject to applicable data protection laws. We use safeguards to protect your information during such transfers.</Text>
                    <Text>Legal Compliance: We may disclose information in response to a request for information that is believed to be required by law, or on orders of law enforcement agencies or courts of law. Where allowed and feasible, we will attempt to provide you with prior notice before disclosing your information in response to such requests or orders.</Text>

                    <Text>Enforcement of our Policies and Rights: Information may be disclosed if such disclosure is required for the enforcement of our Policies or the protection of our rights, property, interests, and the safety of Keikens, our users or third parties.</Text>

                    <Text>Aggregated or De-Identified Information: We may disclose anonymized or aggregated information, which cannot be reasonably used for identification of a user, such as aggregated user statistics for the description of our business.</Text>

                    <Heading as="h2" size="md">DATA RETENTION</Heading>
                    <Text>As detailed above, we are required to collect and receive information from users and others to provide our Services effectively. We retain the information for as long as it is needed for the purposes it has been collected for, meeting our commitments to our users and satisfying the legal requirements.</Text>

                    <Heading as="h3" size="sm">INFORMATION RETAINED TILL USER ACCOUNT IS DELETED:</Heading>
                    <Text>We retain some information during the life of your Keikens account. This includes your email address and password.</Text>

                    <Heading as="h3" size="sm">DATA RETENTION PERIODS FOR SPECIFIC PURPOSES:</Heading>
                    <UnorderedList>
                        <ListItem>User Content: Your Content is stored on our servers for a period of two years, after which the information shall be deleted from our servers. This is retained for us to display your Content to other users.</ListItem>
                        <ListItem>Database Backup: Our database backup is stored in accordance with the applicable policies of our third party service provider, and shall be deleted after the period specified by the third-party service provider.</ListItem>
                        <ListItem>Compliance with Legal Obligations: Certain information is required to be retained for a longer period to comply with legal requirements.</ListItem>
                        <ListItem>Terminated Account: Information including email addresses are stored for a period up to two years, or as per policies of our third-party service provider, in case accounts are terminated for violation of our Policies.</ListItem>
                        <ListItem>Legal Claims: When you contact Keikens for customer support inquiries and requests to access your information, we retain the related information for a period of five years, or as per the policies of our third-party service providers, whichever is shorter, from the closure of your request in case of a dispute or to exercise or defend our legal rights.</ListItem>
                    </UnorderedList>

                    <Heading as="h2" size="md">ACCOUNT DELETION</Heading>
                    <Text>If you have deleted your account, there is no way to recover the same, and it shall be permanent. After your account has been deleted, we begin the process of deleting your information, anonymizing and aggregating other information and statistics.</Text>

                    <Heading as="h2" size="md">INFORMATION PROTECTION</Heading>
                    <Text>We take a number of steps to help protect your information. All information sent within our Services is encrypted both in transit and at rest. We also enforce technical and administrative access controls to limit which of our employees and third-party service providers have access to Personal Identifying Information of the users.</Text>

                    <Heading as="h2" size="md">CHANGES TO THIS PRIVACY POLICY</Heading>
                    <Text>The Privacy Policy is updated from time to time. The date of publication of the latest release is mentioned at the top of the Privacy Policy, and in case of significant changes, we shall notify the users by mail, highlighting the changes within our services, as required by law.</Text>

                    <Heading as="h2" size="md">CONTACT US</Heading>
                    <Text>If you have any questions about our privacy practices or wish to exercise your rights, please contact us at the provided address or email.</Text>
                    <Text>Contact Information:</Text>
                    <Text>Attn: Keikens Customer Service</Text>
                    <Text>953 Wagon Wheel Trail</Text>
                    <Text>Mendota Heights, MN, 55120</Text>
                    <Text>Email: info@keikens.com</Text>

                    <Text>Thank you for choosing Keikens. Your privacy is important to us, and we are committed to protecting it.</Text>
                </VStack>
            </Container>

        </Box>
    );
};

export default PrivacyPolicy;
