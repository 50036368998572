import {Box} from "@chakra-ui/react";
import Jumbotron from "./Jumbotron";
import Page1 from "./Page1";
import Page2 from "./Page2";
import Page3 from "./Page3";

const LandingPage = () => {
    return (
        <Box>
            <Jumbotron/>
            <Page1/>
            <Page2/>
        </Box>
    );
};

export default LandingPage;
