import React, { useState } from 'react';
import {
    Box, Text, Input, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, useDisclosure, useToast
} from '@chakra-ui/react';
import MailingService from '../service/Mailing-Service'; // Ensure the correct path to your MailingService

const SubscriptionBox = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState("");
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { isOpen: isSuccessOpen, onOpen: onSuccessOpen, onClose: onSuccessClose } = useDisclosure();
    const { isOpen: isErrorOpen, onOpen: onErrorOpen, onClose: onErrorClose } = useDisclosure();

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleSubscribe = async () => {
        if (!validateEmail(email)) {
            onErrorOpen();
            return;
        }

        setIsSubmitting(true);
        try {
            const response = await MailingService.subscribe(email);
            console.log(response);
            if (response.success) {
                onSuccessOpen();
            } else {
                if(response.message === "Email already in mailing list") {
                    setError('Hi! You\'re already registered... Thank you for being part of Keikens from the beginning!');
                    onErrorOpen();

                } else {
                    setError("There was a problem adding your email address to our mailing list. Please check that it is a valid email address and try again.\n");
                    onErrorOpen();
                }
            }
        } catch (error) {
            console.error("Exception when calling MailingService.subscribe:", error);
            setError("There was a problem adding your email address to our mailing list. Please check that it is a valid email address and try again.\n");
            onErrorOpen();
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <Box
            w="100%"
            maxW="710px"
            bgColor={'#DFE9ED'}
            py={{ base: "32px", md: "32px" }}
            px={{ base: "48px", md: "78px" }}
            gap={'32px'}
            mt={10}
            borderRadius="35px"
            shadow="md"
        >
            <Text fontSize="16px" fontWeight={'500'} color="black" my={'18px'} align={'left'}>
                We’re in build mode and launching soon. Please provide your email address to fill out a survey and stay connected!
            </Text>
            <Input
                placeholder="Email Address"
                bg="white"
                _hover={{ bg: 'white' }}
                _focus={{ bg: 'white', borderColor: 'blue.500' }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                mb={'10px'}
                isInvalid={!validateEmail(email) && email.length > 0}
            />
            <Button colorScheme="orange" my={"20px"} width="full" borderRadius={'full'} onClick={handleSubscribe} isLoading={isSubmitting}>
                Stay connected
            </Button>

            {/* Success Modal */}
            <Modal isOpen={isSuccessOpen} onClose={onSuccessClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Subscription Successful</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        Thank you for your interest! We will be sharing a brief survey to your email. If you have a few minutes to complete it, we’d appreciate it.                     </ModalBody>
                </ModalContent>
            </Modal>

            {/* Error Modal */}
            <Modal isOpen={isErrorOpen} onClose={onErrorClose} isCentered>
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>Subscription Error</ModalHeader>
                    <ModalCloseButton />
                    <ModalBody>
                        {error}
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Box>
    );
}

export default SubscriptionBox;
