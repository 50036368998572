import axios from "axios";
import authHeader from "./Auth-Header";
import {API_URL} from "./config";

class MailingService {

    subscribe(email) {
        const requestBody = {
            email: email
        };

        return axios.post(API_URL + "/landing/mail/subscribe", requestBody, { headers: authHeader() })
            .then(response => {
                console.log("Subscribe successful:", response);
                return { success: true, data: response.data };
            })
            .catch(error => {
                console.error("Subscribing error:", error);
                if (error.response) {
                    console.error("Error response:", error.response);
                    return { error: true, message: error.response.data || "Error subscribing to mailing list" };
                } else if (error.request) {
                    console.error("Error request:", error.request);
                    return { error: true, message: "No response from server the server" };
                } else {
                    console.error("Error message:", error.message);
                    return { error: true, message: error.message };
                }
            });
    }
}

export default new MailingService();
