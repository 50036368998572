import {Box, Container, Flex, Heading, Image, ListItem, Text, UnorderedList, VStack} from '@chakra-ui/react';
import React from "react";

const About = ({ textColor = '#9F9DAD', textSize = 'md' }) => {

    return (
        <Flex
            direction="column"
            align="center"
            justify="flex-start"
            textAlign="center"
            minH="calc(100vh - 89px)"
            pt={{ base: "5%", md: "5%" }}
            position="relative"
            px={4}
            bgColor={'#FFFCFA'}
        >
            <Image src="/img.png" alt="Left Image" position="absolute" left={{ base: 0, md: "0px"}} top={{ base: "5%", md: "15%" }}
                   display={{ base: "none", md: "block" }} transform="translateY(-50%)" maxH={{ base: "70px", md: "120px" }} />

            <Box p={4} display="flex" justifyContent="center" alignItems="center" maxWidth="900px" width="100%">
                <VStack spacing={4} align="center">
                    <Heading color="#5E8FA3" fontSize={{ base: '28px', md: '40px' }} fontWeight="700">Origin Story</Heading>
                    <Text textAlign="center" fontWeight={'500'} fontSize={20}>
                        As a mom of a neurodiverse kid, I know firsthand the power of experiences in uncovering strengths and fostering inclusivity.
                        When my son was diagnosed, his psychologist told us how important experiences would be in helping him understand his skills and strengths. But finding the right support was hard, and there wasn't one place to go for help.
                    </Text>
                    <Text textAlign="center" fontWeight={'500'} fontSize={20}>
                        That's why I started Keikens (which means 'experience' in Japanese). We're here to help neurodiverse individuals and their families unlock their potential through carefully chosen experiences, resources, and community support. We believe that curiosity leads to knowledge, and experiences lead to understanding.
                    </Text>
                    <Text textAlign="center" fontWeight={'500'} fontSize={20}>
                        Our goal is to positively impact 10 million lives in the next ten years by encouraging curiosity and breaking down stigmas around neurodiversity, one experience at a time. We're working to create a world that values neurodiversity, where every family can access the support they need when they need it.
                    </Text>
                    <Text textAlign="center" fontWeight={'500'} fontSize={20}>
                        Together, we have the power to build a more inclusive and understanding world.
                    </Text>
                    <Text textAlign="center" fontWeight={'500'} fontSize={20}>
                        With passion, grace, and a strong sense of belonging,
                    </Text>
                    <Text textAlign="center" fontWeight={'500'} fontSize={20}>
                        Katie Jacobs
                    </Text>
                    <Text textAlign="center" fontWeight={'500'} fontSize={20}>
                        Founder, Keikens
                    </Text>
                </VStack>
            </Box>
        </Flex>
    );
};

export default About;
