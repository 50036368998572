import {Box, Heading, Text, Image, Flex, Button, Checkbox, VStack, Input, Link} from '@chakra-ui/react';
import React from "react";
import SubscriptionBox from "./SubscriptionBox";

const Jumbotron = () => {
    const Superscript = ({ mobileTop, desktopTop, desktopFontSize, mobileFontSize, children }) => {
        return (
            <Box
                as="sup"
                style={{ position: 'relative' }}
                top={{ base: mobileTop, md: desktopTop }}  // Responsive top positioning
                fontSize={{ base: mobileFontSize, md: desktopFontSize }}
            >
                {children}
            </Box>
        );
    };

    return (
        <Flex
            direction="column"
            align="center"
            justify="flex-start"
            textAlign="center"
            minH="calc(100vh - 89px)"
            pt={{ base: "10%", md: "5%" }}
            position="relative"
            px={4}
            bgColor={'#FFFCFA'}
        >
            <Image src="/img.png" alt="Left Image" position="absolute" left={{ base: 0, md: "16px"}} top={{ base: "33%", md: "19%" }} display={{ base: "none", md: "block" }} transform="translateY(-50%)" maxH={{ base: "50px", md: "120px" }} />
            <Image src="/img_1.png" alt="Right Image" position="absolute" right="0px" top={{ base: "70%", md: "70%" }} display={{ base: "none", md: "block" }} transform="translateY(-50%)" maxH={{ base: "100px", md: "200px" }} />
            <Box maxWidth="900px" width="100%" px={0}>
                <Heading color="#5E8FA3" fontSize={{ base: '28px', md: '58px' }} fontWeight="700">Understanding ...</Heading>
                <Heading color="#5E8FA3" fontSize={{ base: '28px', md: '58px' }} fontWeight="700">is good for everyone.</Heading>
                <Text color="#9F9DAD" mt={4} fontSize={{ base: '18px', md: '31px' }} fontWeight="500">Keikens<Superscript mobileTop="-0.5em" desktopTop="-0.9em" mobileFontSize={'10px'} desktopFontSize={'15px'}>SM</Superscript> [kay-kens] aims to demystify&nbsp;
                <Link href="#neurodiversitySection" >
                     neurodiversity
                </Link>,</Text>
                <Text color="#9F9DAD" mt={0} fontSize={{ base: '18px', md: '31px' }} fontWeight="500">one experience at a time.</Text>

            </Box>
            <SubscriptionBox/>
    </Flex>
    );
};

export default Jumbotron;
