import {Box, Container, Heading, ListItem, Text, UnorderedList, VStack} from '@chakra-ui/react';
import React from "react";

const Terms = ({ textColor = '#9F9DAD', textSize = 'md' }) => {
    return (
        <Box height={'100%'}>
            <Container maxW="container.xl" py="12">
                <VStack spacing="4" align="start">
                    <Heading as="h1" size="xl">KEIKENS WEB APPLICATION END USER LICENSE AGREEMENT
                    </Heading>
                    <Text>LAST UPDATED ON: February 29, 2024</Text>
                    <Text>
                        The Web Application End User License Agreement (“EULA”) is categorized and catalogued into separate links for ease of reference. The terms of the EULA are cumulative and form part of a single document and shall be applicable in its entirety for the use of Services, save for the clauses inconsistent with the federal, state, or local laws applicable where the User is located.                    </Text>
                    <Heading as="h2" size="md">The Partner Agreement includes:</Heading>
                    <UnorderedList>
                        <ListItem>General Clauses</ListItem>
                        <ListItem>About Our Services</ListItem>
                        <ListItem>Provider Account</ListItem>
                        <ListItem>Privacy Policy and User Data</ListItem>
                        <ListItem>Acceptable Use of Our Services</ListItem>
                        <ListItem>Third-Party Services and Links</ListItem>
                        <ListItem>Licenses</ListItem>
                        <ListItem>Fees and Payments</ListItem>
                        <ListItem>Reporting Third-Party Trademark, Copyright, and other Intellectual Property Infringements</ListItem>
                        <ListItem>Disclaimers and Release</ListItem>
                        <ListItem>Limitation of Liability</ListItem>
                        <ListItem>Indemnification</ListItem>
                        <ListItem>Dispute Resolution</ListItem>
                        <ListItem>Availability and Termination of Our Services</ListItem>
                        <ListItem>Miscellaneous</ListItem>
                    </UnorderedList>
                    <Heading as="h2" size="md">GENERAL CLAUSES</Heading>
                    <Text>CONTRACTUAL RELATIONSHIP</Text>
                    <Text>The Web Application End User License Agreement (“EULA”) shall govern the access or use of Keikens web-application, services, and products (“Services”) made available by Keikens LLC, any parent, subsidiary, representative, affiliate, officer, director, licensor, licensee, or other connected parties thereof (“we”, “us”, “Keikens” or “Company”) by any parent or guardian ("you" or "User") using our platform, services, or products.</Text>
                    <Text>By using the Services, you signify your acceptance of the EULA, and agree to be legally bound by the EULA.</Text>
                    <Text>Keikens reserves the sole and exclusive right to change the EULA, Services and Right of Access to Services, without prior intimation to the User.</Text>
                    <Text>If you do not agree to the revised EULA, please do not continue to use it and Keikens shall not be liable for use of the Services by you in such case.</Text>
                    <Text>INTERPRETATION</Text>
                    <Text>Headings are for convenience only and do not define the scope of these Terms.</Text>
                    <Text>We endeavor to provide a safe and inclusive environment for all persons, but any inadvertent use of a gendered pronoun shall be deemed to mean and include all genders and orientations.</Text>
                    <Text>The use of words “include”, “includes”, or “including, refers to “including, but not limited to”, unless otherwise specified.</Text>
                    <Text>The use of singular terms shall include their plural and vice versa.</Text>

                    <Heading as="h2" size="md">ABOUT OUR SERVICES</Heading>
                    <Text>We have created a community-driven platform which allows parents/ guardians to find, shortlist, and book educational and extra-curricular experiences for their school-going children with Providers listed on our Marketplace.</Text>
                    <Text>Your Marketplace:</Text>
                    <Text>Keikens aims to provide an easy and intuitive way for Users to browse, shortlist, register and purchase a variety of ongoing activities and experiences for their children.</Text>
                    <Text>Ways to Improve:</Text>
                    <Text>We analyze the way you interact with Keikens and use it to connect with our Users and Providers, to improve our Services. Keikens uses the information it has and works with Providers and third-party service providers to improve your experience. As part of the efforts to develop new features and products, we may need to add or remove existing features or services. We intend to avoid any disruptions or loss of content, but we cannot guarantee that there will not be any outage or interruptions during these efforts due to which certain content may become irretrievable. We shall not be liable for any such outage, interruption, service change or loss of content.</Text>
                    <Text>Safety, Security, and Integrity:</Text>
                    <Text>Keikens aims to protect the safety, security, and integrity of its Services. This includes appropriately dealing with abusive persons and activities that violate our Terms. Our Policies explicitly prohibit conduct that may be illegal, abusive, or otherwise harmful towards others. We constantly work to prohibit the misuse of our Services and address situations where we may be able to support or protect our community. If prohibited activities come to our notice, we take all necessary actions, including removal of such Users, Providers or Activities from Keikens and approaching law enforcement.</Text>
                    <Text>Enabling Access to Services:</Text>
                    <Text>To operate our Services, we need to store and distribute information in data centers and systems which may be owned and operated by our third-party service providers.</Text>
                    <Text>Customer Details:</Text>
                    <Text>We collect the relevant details of Users for registration, transaction processing and communication. When a User registers for an activity, we promptly notify you with relevant User data. This User data is shared with you for the limited purpose of providing the activity or experience registered for by the User and Providers are not permitted to use such data for any other purpose.</Text>

                    <Heading as="h2" size="md">USER ACCOUNT</Heading>
                    <Text>Registration:</Text>
                    <Text>To access and register for activities on Keikens, you must register as a User. All information you provide must be accurate and current. This includes:</Text>
                    <UnorderedList>
                        <ListItem>_________</ListItem>
                        <ListItem>_________</ListItem>
                        <ListItem>_________</ListItem>
                        <ListItem>_________</ListItem>
                        <ListItem>_________</ListItem>
                    </UnorderedList>
                    <Text>If the information provided by you is found to be false, incorrect, or otherwise fraudulent, Keikens reserves the right to terminate your account in accordance with our Policies, and may also approach the law enforcement agencies, if deemed necessary.</Text>

                    <Heading as="h2" size="md">Account Security:</Heading>
                    <Text>The security of your account is your responsibility, and you agree to notify us immediately if you believe that your account has been compromised. For the sake of your account security, ensure that you use a unique and strong password for your Keikens account and do not share it with anybody else. You are responsible for providing Keikens with information regarding changes in any information submitted at the time of registration. In case, you do not provide us with the updated information and are unable to access your account for such reason, Keikens shall not be held liable.</Text>

                    <Heading as="h2" size="md">PRIVACY POLICY AND USER DATA</Heading>
                    <Text>Privacy is of utmost importance to us. Keikens’ Privacy Policy reflects our practices with respect to data collected and received from you, the processing of the data collected, and your rights in relation to the processing of your data.</Text>

                    <Heading as="h2" size="md">ACCEPTABLE USE OF OUR SERVICES</Heading>
                    <Text>Users are required to use our Services in accordance with our Policies. If a User is found in violation of our Policies, Keikens reserves the right to take necessary action, including, disabling, suspending, or terminating the concerned account in accordance with our Policies. If your account is disabled, suspended, or terminated for the violation of our Policies, you shall not be allowed to create another account for access to our Services.</Text>

                    <Text>Legal and Acceptable Use:</Text>
                    <UnorderedList>
                        <ListItem>Violate, misappropriate, or infringe the rights, including, privacy, publicity, intellectual property, personal safety, mental health or, other proprietary or person rights, of Keikens, other Providers, Users, or any third-party;</ListItem>
                        <ListItem>Cause, instigate, promote, assist, or encourage illegal, obscene, defamatory, threatening, intimidating, harassing, hateful or offensive conduct towards any race, caste or ethnicity, violent crimes, endangerment or exploitation of children or others, coordinate harm, or any other conduct that goes against public morality;</ListItem>
                        <ListItem>Publish or promote falsehoods, misrepresentations, misleading statements, imputations on character or conduct of Keikens, other Providers, users, or any third-party;</ListItem>
                        <ListItem>Impersonate someone;</ListItem>
                        <ListItem>Send illegal or impermissible communications including bulk messaging, obscene of illegal Content, and other such communications; or</ListItem>
                        <ListItem>Involve any other use of our Services which is not consistent with the objectives and nature of the platform.</ListItem>
                    </UnorderedList>

                    <Text>Harm to Keikens, Providers or Users:</Text>
                    <UnorderedList>
                        <ListItem>Reverse engineer, alter, modify, create derivative works from, decompile, or extract code from our Services;</ListItem>
                        <ListItem>Send, store, or transmit viruses or other harmful computer code through or onto our Services;</ListItem>
                        <ListItem>Gain or attempt to gain unauthorized access to our Services or systems;</ListItem>
                        <ListItem>Interfere with or disrupt the safety, security, confidentiality, integrity, availability, or performance of our Services;</ListItem>
                        <ListItem>Create accounts for our Services through unauthorized or automated means;</ListItem>
                        <ListItem>Collect information of or about our users in any impermissible or unauthorized manner;</ListItem>
                        <ListItem>Sell, resell, rent, or charge for our Services or data obtained from us or our Services in an unauthorized manner;</ListItem>
                        <ListItem>Distribute or make our Services available over a network where they could be used by multiple devices at the same time, except as authorized through tools we have expressly provided via our Services;</ListItem>
                        <ListItem>Create software or APIs that function substantially the same as our Services and offer them for use by third parties in an unauthorized manner; or</ListItem>
                        <ListItem>Misuse any reporting channels, such as by submitting fraudulent or groundless reports or appeals.</ListItem>
                    </UnorderedList>
                    <Heading as="h2" size="md">Proprietary Rights and Your Content:</Heading>
                    <Text>We do not claim any ownership of the information that you submit for your account and the Content that you share through our Services. Keikens acknowledges and respects your exclusive ownership and intellectual property rights over your content.</Text>
                    <Text>Similarly, we do not take any responsibility for your Content or the use of your Content by others.</Text>
                    <Text>We endeavor to create a safe environment for all our Users and Providers, and if your Content is violative of our community guidelines, we shall be obligated to remove such content from Keikens at our discretion and inform the law enforcement agencies, if deemed necessary.</Text>
                    <Text>In the use of our Services, we grant you access to Content shared by other Providers and Users through our Services. You may not use such Content without the consent of the Provider or User who has shared the Content, or in violation of applicable laws.</Text>
                    <Text>The Content of the Providers and Users is theirs alone, and neither is the content reflective of Keikens’ views, nor is it under our control. We work hard to make Keikens a safe, user friendly, positive, and inclusive platform, but cannot prevent you from encountering Content that may be objectionable or offensive to you. You agree that Keikens shall not be liable for any harm caused by such Content.</Text>
                    <Text>If you feel that the Content is violative of our Policies, the same can be reported. We have the right, but not the obligation, to review such reports, and block or remove such Content at our discretion.</Text>

                    <Heading as="h2" size="md">Community Guidelines:</Heading>
                    <Text>By using the Services, you agree that you will not:</Text>
                    <UnorderedList>
                        <ListItem>Use the Services for any purpose that is illegal or prohibited by our Policies;</ListItem>
                        <ListItem>Use the Services for any harmful or nefarious purpose;</ListItem>
                        <ListItem>Use the Services to damage Keikens;</ListItem>
                        <ListItem>Violate our Community Guidelines, as updated from time to time;</ListItem>
                        <ListItem>Spam, solicit money from or defraud any other Users;</ListItem>
                        <ListItem>Impersonate any person or entity or post any images of another person without his or her permission;</ListItem>
                        <ListItem>Bully, "stalk", intimidate, assault, harass, mistreat, or defame any person;</ListItem>
                        <ListItem>Post any Content that violates or infringes anyone's rights, including rights of publicity, privacy, copyright, trademark or other intellectual property or contract right;</ListItem>
                        <ListItem>Post any Content that is hate speech, threatening, sexually explicit or pornographic;</ListItem>
                        <ListItem>Post any Content that incites violence; or contains nudity or graphic or gratuitous violence;</ListItem>
                        <ListItem>Post any Content that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual;</ListItem>
                        <ListItem>Solicit passwords for any purpose, or personal identifying information for commercial or unlawful purposes from other members or disseminate another person's personal identifying information without his or her permission;</ListItem>
                        <ListItem>Use another user's account, share an account with another user, or maintain more than one account;</ListItem>
                        <ListItem>Create another account if we have already terminated your account, unless you have our permission.</ListItem>
                    </UnorderedList>
                    <Text>Keikens reserves the right to investigate and/or terminate your account if you have violated this Agreement, misused the Services, or behaved in a way that Keikens regards as inappropriate or unlawful, including actions or communications that occur on or off the Service.</Text>
                    <Heading as="h2" size="md">THIRD-PARTY SERVICES AND LINKS</Heading>
                    <Text>Our Services may allow you to access, use, or interact with third-party websites, applications, Content, products, or services. For example, third-party data backup services and payment gateways/ aggregators. Please note that our Policies apply only to your use of our Services. When you use third-party services or products, their respective terms of use and policies shall govern your use of those products or services.</Text>
                    <Text>Keikens may contain links to third-party websites or services, but we do not endorse them.</Text>

                    <Heading as="h2" size="md">LICENSES</Heading>
                    <Heading as="h3" size="sm">License by User:</Heading>
                    <Text>Your Content may be protected by certain intellectual property rights. We do not own them. By using our Services, you grant us a license- which is in the form of permissions – to do the following with your Content, in accordance with the applicable legal requirements, in connection with operating, developing, and improving our Services:</Text>
                    <UnorderedList>
                        <ListItem>Use, copy, store, distribute, and communicate your Content in manners consistent with your use of the Services. (For example, we can store and display your Content)</ListItem>
                        <ListItem>Publish, publicly perform, or publicly display your Content. (For example, we can display the reviews posted by you in respect of our Providers or their activities and experiences)</ListItem>
                        <ListItem>Monitor, modify, translate, and reformat your Content. (For example, we can resize an image you post to fit on a mobile device)</ListItem>
                        <ListItem>Sublicense your Content to allow our Services to work as intended. (For example, we can store your Content with our cloud service providers.)</ListItem>
                    </UnorderedList>
                    <Text>This license is worldwide, non-exclusive, royalty-free, transferable, and perpetual.</Text>
                    <Text>We reserve the right to block, remove, and/or permanently delete your Content for any reason including breach of our Policies, or any applicable law or regulation.</Text>

                    <Heading as="h3" size="sm">Keikens’ License:</Heading>
                    <Text>We own all copyrights, trademarks, domains, logos, trade dress, trade secrets, patents, and other intellectual property rights associated with our Services. You may not use any of these intellectual property rights unless expressly permitted by us.</Text>
                    <Text>We grant you a limited, revocable, non-exclusive, non-sublicensable, and non-transferable license to use our Services, subject to and in accordance with our Terms.</Text>

                    <Heading as="h2" size="md">FEES AND PAYMENTS</Heading>
                    <Text>The payment of Registration Fees by the Users at the time of registering for activities or experiences shall be made through our third-party service provider, and we shall not be a party to such transactions.</Text>
                    <Text>Providers set their own Registration Fees and their own cancellation policies. Cancellations and refunds (if any) shall be exclusively between the Provider and the User.</Text>

                    <Heading as="h2" size="md">REPORTING THIRD-PARTY TRADEMARK, COPYRIGHT AND OTHER INTELLECTUAL PROPERTY INFRINGEMENTS</Heading>
                    <Text>We may take action with respect to your account, including disabling your account, if you clearly, seriously, or repeatedly infringe the intellectual property rights of others or where we must do so for legal reasons in accordance with our Policies.</Text>
                    <Text>To report any intellectual property infringement and request us to remove any infringing Content on our platform, please email a completed infringement claim to _______________.</Text>
                    <UnorderedList>
                        <ListItem>Complete Contact Information: Full Name, Email Address, and Phone Number.</ListItem>
                        <ListItem>Description of Intellectual Property: A description of the intellectual property that you claim has been infringed.</ListItem>
                        <ListItem>Description of Content: A description of the Content that you claim infringes your intellectual property rights.</ListItem>
                        <ListItem>Declaration: A declaration that you have a good faith belief that use of the reported Content is without the consent or authorization of the owner of the intellectual property; the information in your claim is correct and complete; you declare under penalty of perjury, that you are the rightful owner or authorized representative of the owner of the intellectual property that is allegedly infringed; your electronic or physical signature.</ListItem>
                    </UnorderedList>
                    <Heading as="h2" size="md">DISCLAIMERS AND RELEASE</Heading>
                    <Text>We work hard to offer Services that are safe, user friendly, positive, and inclusive, but there are certain aspects that we cannot guarantee. You agree that the use of our Services is at your own risk and is subject to the following disclaimers.</Text>
                    <Text>WE ARE PROVIDING OUR SERVICES ON AN “AS IS” BASIS WITHOUT ANY EXPRESS OR IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, NON-INFRINGEMENT, AND FREEDOM FROM COMPUTER VIRUS OR OTHER HARMFUL CODE. WE DO NOT WARRANT THAT ANY INFORMATION PROVIDED BY US IS ACCURATE, COMPLETE, OR USEFUL, THAT OUR SERVICES WILL BE OPERATIONAL, ERROR FREE, SECURE, OR SAFE, OR THAT OUR SERVICES WILL FUNCTION WITHOUT DISRUPTIONS, DELAYS, OR IMPERFECTIONS. WE DO NOT CONTROL, AND ARE NOT RESPONSIBLE FOR, CONTROLLING HOW OR WHEN OUR USERS USE OUR SERVICES OR THE FEATURES, SERVICES, AND INTERFACES OUR SERVICES PROVIDE. WE ARE NOT RESPONSIBLE FOR AND ARE NOT OBLIGATED TO CONTROL THE ACTIONS OR INFORMATION (INCLUDING CONTENT) OF OUR USERS OR OTHER THIRD PARTIES. YOU RELEASE US, OUR SUBSIDIARIES, AFFILIATES, AND OUR AND THEIR DIRECTORS, OFFICERS, EMPLOYEES, PARTNERS, AND AGENTS (“KEIKENS PARTIES”) FROM ANY CLAIM, COMPLAINT, CAUSE OF ACTION, CONTROVERSY, DISPUTE, OR DAMAGES (“CLAIM”), KNOWN AND UNKNOWN, RELATING TO, ARISING OUT OF, OR IN ANY WAY CONNECTED WITH ANY SUCH CLAIM YOU HAVE AGAINST ANY THIRD PARTIES.</Text>

                    <Heading as="h2" size="md">LIMITATION OF LIABILITY</Heading>
                    <Text>WE DO NOT EXCLUDE OR LIMIT OUR LIABILITY TO YOU WHERE IT WOULD BE ILLEGAL TO DO SO. IN JURISDICTIONS WHERE THE BELOW TYPES OF EXCLUSIONS ARE PROHIBITED, WE ARE RESPONSIBLE TO YOU ONLY FOR LOSSES AND DAMAGES THAT ARE A REASONABLY FORESEEABLE RESULT OF OUR FAILURE TO USE REASONABLE CARE AND SKILL OR OUR BREACH OF OUR CONTRACT WITH YOU.</Text>
                    <Text>IN JURISDICTIONS WHERE EXCLUSIONS OR LIMITATIONS OF LIABILITY ARE ALLOWED, NONE OF THE KEIKENS PARTIES INVOLVED IN CREATING, PRODUCING, OR DELIVERING THE SERVICES WILL BE LIABLE, TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, FOR ANY INCIDENTAL, SPECIAL, EXEMPLARY, OR CONSEQUENTIAL DAMAGES, OR DAMAGES FOR LOST PROFITS, LOST REVENUES, LOST SAVINGS, LOST BUSINESS OPPORTUNITY, LOSS OF DATA OR GOODWILL, SERVICE INTERRUPTION, COMPUTER DAMAGE OR SYSTEM FAILURE, OR THE COST OF SUBSTITUTE SERVICES OF ANY KIND ARISING OUT OF OR IN CONNECTION WITH THESE TERMS OR FROM THE USE OF OR INABILITY TO USE THE SERVICES, WHETHER BASED ON WARRANTY, CONTRACT, STATUTE, TORT (INCLUDING NEGLIGENCE), PRODUCT LIABILITY, OR ANY OTHER LEGAL THEORY, AND WHETHER OR NOT KEIKENS OR ITS SUPPLIERS HAVE BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGE, EVEN IF A LIMITED REMEDY PROVIDED IN THESE TERMS IS FOUND TO HAVE FAILED OF ITS ESSENTIAL PURPOSE.</Text>

                    <Heading as="h2" size="md">INDEMNIFICATION</Heading>
                    <Text>Subject to any changes in the applicable laws, if any third-party brings a claim against the Keikens Parties in relation to your actions, information or Content on Keikens or any other use of our Services by you, you will, to the maximum extent permitted by applicable law, indemnify and hold the Keikens Parties harmless from and against all liabilities, damages, losses, and expenses of any kind (including reasonable legal fees and costs) relating to, arising out of, or in any way connected with:</Text>
                    <UnorderedList>
                        <ListItem>Your access to or use of our Services, including information and Content shared in connection therewith;</ListItem>
                        <ListItem>Your breach of our Policies or the applicable laws; or</ListItem>
                        <ListItem>Any misrepresentation made by you.</ListItem>
                    </UnorderedList>
                    <Text>You agree to extend your cooperation, as fully required by us, in the defense or settlement of any third-party claim.</Text>
                    <Heading as="h2" size="md">DISPUTE RESOLUTION</Heading>
                    <Text>In case any disputes arise with relation to, arising out of, or in connection with our Policies, the User and Keikens agree that any such claim or cause action (“Dispute”) will be resolved in accordance with this section.</Text>
                    <Heading as="h3" size="sm">Informal Resolution:</Heading>
                    <Text>The dispute shall be intimated by the aggrieved party through a notice of dispute, and the parties shall endeavor to amicably settle the Dispute through mutual discussion and agreement within thirty (30) days from the receipt of the Notice of Dispute.</Text>
                    <Heading as="h3" size="sm">Mediation-Arbitration:</Heading>
                    <Text>If the Dispute cannot be resolved through mutual discussion and agreement, the dispute shall be referred to mediation before a Qualified Neutral under Rule 114 of the Minnesota General Rules of Practice who shall be appointed mutually by the Parties. If the Dispute cannot be resolved through mediation, the appointed neutral shall act as an arbitrator and decide the Dispute. The decision of the arbitrator, in writing, shall be final and binding upon the Parties.</Text>
                    <Heading as="h3" size="sm">Governing Law:</Heading>
                    <Text>The laws applicable in the State of Minnesota, United States of America govern our Terms, as well as any Dispute between a Provider and Keikens, and the courts in Minnesota shall have jurisdiction over any Dispute arising out of the Partner Agreement.</Text>
                    <Heading as="h3" size="sm">Notice of Dispute:</Heading>
                    <Text>The Notice of Dispute must contain- (i) name; (ii) residence address; (iii) username; (iv) registered email address and (v) detailed description of the dispute; and (f) relief sought.</Text>
                    <Heading as="h3" size="sm">Opt-out Procedure:</Heading>
                    <Text>You may opt out of this agreement to arbitrate. To opt out, you must notify us in writing, within thirty (30) days of: (a) the date that you first accepted our Terms; and (b) the date you became subject to this arbitration provision. The communication must include your name, residence address, registered mobile number and email address, and a clear statement that you want to opt out of the agreement to arbitrate under our Terms.</Text>
                    <Heading as="h3" size="sm">Class-Actions, Class-Arbitrations or Representative Suits:</Heading>
                    <Text>Keikens and the User agree that any action, arbitration, or suit shall be brought on their own behalf only, and not on behalf of any other person or entity, or class of people. We and you agree not to take part in a class action, class-wide arbitration, disputes brought in a representative capacity, or consolidated disputes involving any other person or entity in connection with any Dispute.</Text>

                    <Heading as="h2" size="md">AVAILABILITY, TERM AND TERMINATION OF OUR SERVICES</Heading>
                    <Heading as="h3" size="sm">Availability:</Heading>
                    <Text>We are always trying to improve our Services. Our Services may be interrupted, including for maintenance, repairs, upgrades, or failures of network, servers, or equipment. Events beyond our control may affect our Services, such as events in nature and force majeure events.</Text>
                    <Heading as="h3" size="sm">Term:</Heading>
                    <Text>This EULA shall be valid for a term of one year from the date of registration of the user and shall be deemed to be renewed at the end of the term, unless otherwise specified in writing by the User.</Text>
                    <Heading as="h3" size="sm">Termination:</Heading>
                    <Text>We may modify, suspend, or terminate your access to or use of our Services, in events including the violation of the letter and spirit of our Terms. In such events, we shall provide you with an opportunity to prove your innocence, however, the decision of Keikens shall be final.</Text>

                    <Heading as="h2" size="md">MISCELLANEOUS</Heading>
                    <Text>The Partner Agreement along with the policies cover the entire agreement between Keikens and the Provider for the use of our Services. Where additional terms apply to our products or services, the additional terms will control with respect to your use of that product or service to the extent of any conflict of these terms. We support the responsible reporting of security vulnerabilities. To report a security issue or other bugs, please email at _____________________.</Text>
                    <Heading as="h2" size="md">Confidential Information & Data</Heading>
                    <Text>Both parties agree to protect confidential information disclosed during the term of this EULA.</Text>

                    <Heading as="h2" size="md">Limitation on use</Heading>
                    <Text>Our Services are not intended for distribution to or use in any jurisdiction where such distribution or use would violate the local law or would subject us to any regulations in another country. We reserve the right to limit our Services in any such jurisdiction.</Text>

                    <Heading as="h2" size="md">Applicable Language</Heading>
                    <Text>Our Policies are written in English (United States). Any translated version is provided solely for the purpose of convenience. To the extent of any conflict in translated versions of our Terms or in the interpretation of any section, the English (United States) version shall be binding.</Text>

                    <Heading as="h2" size="md">Amendments and Waivers</Heading>
                    <Text>Any amendment or waiver in our Terms proposed by the User requires our express consent for applicability. Further, any amendment or waiver granted to a particular user may not automatically make another User eligible for such amendment or waiver, and any such request shall be considered on a case-to-case basis.</Text>
                    <Text>We may amend or update our Terms. Keikens will provide the Provider with a notice of material amendments to our Terms, as appropriate, and the “Last Modified” date at the top shall be changed to reflect any amendments, updates, or other modifications. The User’s continued use of our Terms confirms your acceptance of our Terms, as amended, updated, or otherwise modified. While Keikens looks forward to your continued use, if you do not agree with our Terms, stop using the Services by deleting your user account and uninstall any connected softwares.</Text>
                    <Text>Nothing in our Terms will prevent Keikens from complying with the applicable law.</Text>
                    <Text>If Keikens fails to enforce any of our Policies, it will not be considered as a waiver, unless expressly communicated by us.</Text>

                    <Heading as="h2" size="md">Assignment, Transfer, Third-Party Interest</Heading>
                    <Text>All our rights and obligations under our Policies are freely assignable by us to any of our affiliates or in connection with a merger, acquisition, restructuring, or sale of assets, or by operation of law or otherwise, and we may transfer your information to any of our affiliates, successor entities, or new owner. In the event of such an assignment, the Partner Agreement will continue to govern your relationship with such third-party. While we look forward to your continued use, if you do not agree with such assignment, stop using the Services by deleting your user account and uninstall any connected softwares after having been notified of the assignment.</Text>
                    <Text>The Provider cannot transfer, assign, or create any third-party or beneficiary interests with respect to any rights or obligations under our Terms without the express written consent of Keikens.</Text>

                    <Heading as="h2" size="md">Survival</Heading>
                    <Text>The termination of the relationship between Keikens and the User shall not affect the applicability of certain sections. In other words, the following sections will survive any termination of your relationship with Keikens: "Licenses", "Disclaimers and Release", "Limitation of Liability", "Indemnification", "Dispute Resolution", "Availability and Termination of Our Services", and "Miscellaneous".</Text>

                    <Heading as="h2" size="md">Exclusivity</Heading>
                    <Text>Providers agree not to use the services of a Direct Competitor of Keikens in respect of the Services being availed from Keikens with respect to activities and experiences.</Text>

                    <Heading as="h2" size="md">Severability</Heading>
                    <Text>If any provision of the EULA is found to be unlawful, void, or for any reason unenforceable, then that provision shall be deemed amended to the minimum extent necessary to make it enforceable, and if it cannot be made enforceable then it shall be deemed severable from the EULA and shall not affect the validity and enforceability of the remaining provisions of the EULA, and the remaining portion of EULA will remain in full force and effect except as expressly provided in our Terms.</Text>

                    <Heading as="h2" size="md">Notice</Heading>
                    <Text>All notices must be in writing and deemed received upon delivery.</Text>

                    <Heading as="h2" size="md">Questions?</Heading>
                    <Text>Contact us at info@keikens.com or Keikens Customer Service, 953 Wagon Wheel Trail, Mendota Heights, MN 55120.</Text>
                </VStack>
            </Container>

        </Box>
    );
};

export default Terms;
